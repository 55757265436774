@font-face {
  font-family: "gotham-bold";
  src: url(../public/fonts/GothamHTF-Bold.woff2) format("woff2");
  font-display: auto;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "gotham-book";
  src: url(../public/fonts/GothamHTF-Book.woff2) format("woff2");
  font-display: auto;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "gotham-medium";
  src: url(../public/fonts/GothamHTF-Medium.woff2) format("woff2");
  font-display: auto;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "gotham-black";
  src: url(../public/fonts/GothamHTF-Black.woff2) format("woff2");
  font-display: auto;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "greta-reg";
  src: url(../public/fonts/GretaTextStd-Regular.woff2) format("woff2");
  font-display: auto;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "greta-reg-italic";
  src: url(../public/fonts/GretaTextStd-Italic.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "greta-bold";
  src: url(../public/fonts/GretaTextStd-Bold.woff2) format("woff2");
  font-display: auto;
  font-weight: 400;
  font-style: normal;
}

html {
  height: -webkit-fill-available;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.wrapper {
  min-height: -webkit-fill-available;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: black;
}
main {
  flex: 1 0 auto;
  background-color: #fff;
}
footer {
  background-color: beige;
  flex-shrink: 0;
}
.map-container {
  height: 400px;
}
.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
